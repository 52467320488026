import React, { useEffect } from "react";

const mobileType = navigator.userAgent.toLowerCase();

const appleLink = "https://apps.apple.com/kr/app/id6462087677";
const androidLink =
  "https://play.google.com/store/apps/details?id=kr.co.helperits.android";

export default function Link() {
  useEffect(() => {
    if (mobileType.includes("android")) {
      window.location.replace(androidLink);
    } else if (
      mobileType.includes("iphone") ||
      mobileType.includes("ipad") ||
      mobileType.includes("ipod")
    ) {
      window.location.replace(appleLink);
    } else {
      window.location.replace("/");
    }
  });

  return null;
}
